// import { navigate } from "gatsby"

import { Button, Input, Form, Spin } from "antd"
import React, { useRef, useState } from "react"
import "../index.css"
import { navigate } from "gatsby"
import {
  checkEmail,
  makeRequestSendAnUrlResetPassword,
} from "../../../services/user"
const DisplayInputEmail = ({ setSentEmail }) => {
  const ref = useRef()
  const [form] = Form.useForm()
  const [errorEmail, setErrorEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      console.log("DATA BEFORE CLICK IS :", values.email)
      const response = await checkEmail(values.email)
      console.log("data response is :", response.data?.exists)
      if (response.response.status === 200 && response.data.exists === true) {
        console.log("email exist")
        setErrorEmail(false)
        const resultSentEmail = await makeRequestSendAnUrlResetPassword(
          values.email,
          "admin"
        )
        if (resultSentEmail.response.status === 204) {
          console.log("an email reset password have been send")
          setSentEmail(true)
        } else {
          console.log("error send email")
          setErrorEmail(true)
        }

        return
      } else {
        console.log("email does not exist")
        setErrorEmail(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div className="flex h-full w-full  justify-center mt-14 mb-[18%]">
      <div className="flex w-[820px] bg-grey-0 rounded-rounded justify-center flex-col">
        <div className="flex flex-col pt-14 w-full px-[150px] ">
          <div className="text-[#ECB427] font-bold text-[22px] flex justify-center ">
            パスワードの再設定
          </div>
          {!errorEmail && (
            <div className="mt-8">
              <div>
                ご登録頂いたメールアドレスを入力し「送信」ボタンをクリックしてください。
              </div>
              <div>パスワード再設定の手順を記載したメールを送付致します。</div>
            </div>
          )}{" "}
          {errorEmail && (
            <div className="mt-8 text-rose-400">
              <div>ご入力頂いたメールアドレスでは登録がありません。</div>
              <div>再度メールアドレスをご入力ください。</div>
            </div>
          )}
          <div className="mt-[16px]">
            <Form
              className="pt-6"
              onFinish={handleSubmit}
              onChange={() => {
                setErrorEmail(false)
              }}
              form={form}
              ref={ref}
            >
              <Form.Item
                name={"email"}
                rules={[
                  {
                    type: "email",
                    message: `有効な電子メールではありません。`,
                  },
                  {
                    required: true,
                    message: `メールアドレスを入力してください。`,
                  },
                ]}
              >
                <Input placeholder={"メールアドレス"} />
              </Form.Item>
              <div className="line_full_width_56 mt-10"></div>
              <div className=" flex justify-center ">
                <Spin spinning={loading}>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#EC4D43" }}
                    className="rounded bg-[#E60020] text-[#fff] focus:bg-rose-500 hover:bg-rose-500 rounded-[5px] mt-3  w-[320px] inter-base-regular"
                    size="large"
                    htmlType="submit"
                    disabled={loading}
                  >
                    再設定メールを送信
                  </Button>
                </Spin>
              </div>
            </Form>
          </div>
          <div className="flex justify-center mt-4 text-rose-500 mb-[80px] ">
            <div
              className="w-fit hover:border-b hover:border-rose-400 cursor-pointer"
              onClick={() => {
                navigate("/")
              }}
            >
              ログイン画面に戻る
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisplayInputEmail
