import { navigate } from "gatsby"
import { Button } from "antd"
import React from "react"
import "../index.css"

const DisplaySentEmail = () => {
  return (
    <div className="flex h-full w-full  justify-center mt-14 mb-[18%]">
      <div className="flex w-[820px] bg-grey-0 rounded-rounded justify-center flex-col">
        <div className="flex flex-col pt-14 w-full px-[150px] ">
          <div className=" font-bold text-[22px] flex justify-center flex-row items-center gap-[8px]">
            <div>
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="14.5"
                  cy="14"
                  r="13"
                  fill="white"
                  stroke="#EA6D69"
                  strokeWidth="2"
                />
                <path
                  d="M6.80078 14L11.7008 18.9L21.5008 9.09998"
                  stroke="#EA6D69"
                  strokeWidth="3"
                />
              </svg>
            </div>
            <div className="text-[#EA6D69]">送信完了</div>
          </div>
          <div className="mt-9">
            <div>
              ご登録のメールアドレスにパスワード再設定に関するメールを送信しました。
            </div>
            <div> 受信BOXをご確認のうえ、再設定の手続きをお願い致します。</div>
            <div>
              30分以上経過しても全ての受信BOXに届かない場合には、大変お手数ですが
            </div>
            <div>再申請をお願い致します。</div>
          </div>
          <div className="line_full_width_56 mt-14"></div>
          <div className=" flex justify-center ">
            <Button
              type="primary"
              className="rounded bg-[#E60020] text-[#fff] focus:bg-rose-500 hover:bg-rose-500 rounded-[5px] mt-3 mb-[105px] w-[320px] inter-base-regular"
              size="large"
              onClick={() => {
                navigate("/login")
              }}
            >
              ログインする
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisplaySentEmail
